import type {
  HeadlineResponse,
  HeadlineResponsePagedData,
  LatestForumPostsResponse,
  ScoreboardResponseRelatedScheduleResponseRelatedPagedData,
  SimpleHeadlineResponseSimpleSiteResponseRecordRelatedPagedData,
  UserProfileResponseRecord,
} from '@on3/api';
import { useSite } from '@on3/ui-lib/index';
import { MPU } from 'components/Ads/MPU';
import { Forums } from 'components/Forums/Forums';
import { FeedCarousel } from 'components/Headlines/ArticleCarousel/FeedCarousel';
import { CondensedList } from 'components/Headlines/ArticleCondensed/CondensedList';
import { ArticlesList } from 'components/Headlines/ArticleLarge/ArticlesList';
import { PremiumArticleList } from 'components/Headlines/PremiumArticle/PremiumArticleList';
import { SiteLogo } from 'components/Image/SiteLogo';
import { HomeLayout } from 'components/Layouts/HomeLayout';
import { ScheduleModule } from 'components/Schedules/Sidebar/Module';
import { TeamConnect } from 'components/Social/TeamConnect';
import { PodcastPlayer } from 'components/Teams/Podcast/PodcastPlayer';
import { TextLink } from 'components/UI/Link/TextLink';

import styles from './FeedHome.module.scss';
import { SEOHomeText } from './SEOHomeText';

export interface IFeedHomeProps {
  featuredArticles: HeadlineResponse[] | null;
  mainFeed: HeadlineResponsePagedData | null;
  channelSports: HeadlineResponse[] | null;
  channelRecruiting: HeadlineResponse[] | null;
  featuredAuthors: UserProfileResponseRecord[] | null;
  premiumArticles: SimpleHeadlineResponseSimpleSiteResponseRecordRelatedPagedData | null;
  messageBoard: LatestForumPostsResponse | null;
  teamSchedule: ScoreboardResponseRelatedScheduleResponseRelatedPagedData | null;
}

const podcastData = {
  allHref:
    '/teams/kentucky-wildcats/news/your-guide-on-how-to-listen-to-the-ksr-podcast-network/',
  src: 'https://playlist.megaphone.fm/?p=LRL4571054944',
};

export const FeedHome = ({
  mainFeed,
  featuredArticles,
  channelSports,
  channelRecruiting,
  premiumArticles,
  messageBoard,
  teamSchedule,
}: IFeedHomeProps) => {
  const { currentSite } = useSite();

  return (
    <>
      <div className={styles.ksrBanner}>
        <TextLink href={`${currentSite?.url}/`}>
          <SiteLogo className={styles.logo} width={50} />
        </TextLink>
      </div>
      {featuredArticles?.length && (
        <FeedCarousel list={featuredArticles.slice(0, 4)} />
      )}
      <SEOHomeText />
      <HomeLayout>
        <section className={styles.feed}>
          <div className={styles.inner}>
            {mainFeed?.list?.length && (
              <ArticlesList
                list={mainFeed.list}
                pagination={mainFeed.pagination}
              />
            )}
          </div>
        </section>
        <aside className={styles.sidebarright}>
          <div className={styles.inner}>
            <MPU id="mpu_top" />
            {!!premiumArticles?.list?.length && (
              <PremiumArticleList
                list={premiumArticles?.list}
                relatedModel={premiumArticles?.relatedModel}
              />
            )}
            {!messageBoard?.posts?.length || <Forums list={messageBoard} />}
            {!!teamSchedule?.list?.length && (
              <ScheduleModule
                list={teamSchedule?.list}
                relatedModel={teamSchedule?.relatedModel}
              />
            )}
            <MPU id="mpu_middle" />
          </div>
        </aside>
        <aside className={styles.sidebarleft}>
          <div className={styles.inner}>
            <CondensedList
              list={channelSports}
              secondaryList={channelRecruiting}
            />
            <PodcastPlayer data={podcastData} />

            <div className={styles.sticky}>
              <TeamConnect />
            </div>
          </div>
        </aside>
      </HomeLayout>
    </>
  );
};
